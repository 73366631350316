import axios from 'axios'
import {
  type OrderType,
  type AudienceType,
  type ProductType,
  type PackageType
} from '../utils/types'
import { getApiKey } from '../utils/apiKeyStorage'

const apiBaseUrl = process.env.REACT_APP_API_URL

const apiClient = axios.create({
  baseURL: apiBaseUrl
})

// Add a request interceptor to include the API key
apiClient.interceptors.request.use(
  async (config) => {
    const apiKey = getApiKey() // Synchronously fetch the API key
    console.log('apiKey ', apiKey)
    if (apiKey) {
      config.headers['x-api-key'] = apiKey // Add the API key to headers
    }
    return await Promise.resolve(config) // Explicitly return an awaited promise
  },
  async (error) => {
    return await Promise.reject(error) // Explicitly await the rejection
  }
)

interface OrderFrequencyResponse {
  catalogAccess: boolean
  nbrDays?: number
}

interface MergedProductType extends ProductType {
  fromProducts: boolean
}

const addOrder = async (
  newOrder: Partial<OrderType>
): Promise<AudienceType> => {
  try {
    const response = await apiClient.post('/orders', newOrder)
    return response.data
  } catch (error: any) {
    // Use optional chaining to access error response
    const message = error?.response?.data?.message || 'Network error'
    const type = error?.response?.data?.type || 'Unknown error'

    // Throw a proper error object
    const customError = new Error(message)
    customError.name = type
    throw customError
  }
}

const getOrderByAccountNumber = async (
  accountNumber: string
): Promise<OrderType[]> => {
  try {
    const response = await apiClient.get(
      `/orders/history/${accountNumber}`
    )
    return response.data
  } catch (error) {
    throw new Error('Network error')
  }
}

const checkOrderFrequency = async (
  data: { accountNumber: string, audience: AudienceType } | string
): Promise<OrderFrequencyResponse> => {
  try {
    const response = await apiClient.post(
      '/orders/order-frequency',
      data
    )
    return response.data
  } catch (error) {
    throw new Error('Network error')
  }
}

const validateOrderData = (
  audience: AudienceType,
  quantity: number
): { isValid: boolean, message: string } => {
  const { maxOrder, minOrder } = audience

  // Case 1: Both maxOrder and minOrder are null
  if (maxOrder === null && minOrder === null) {
    return { isValid: true, message: '' }
  }

  // Case 2: Both maxOrder and minOrder are not null
  if (maxOrder !== null && minOrder !== null) {
    if (quantity < minOrder) {
      return {
        isValid: false,
        message: `La quantité minimale de cartouches commandées ne doit pas être inférieure à ${minOrder}.\n
          Merci de vérifier les quantités de votre panier.`
      }
    }
    if (quantity > maxOrder) {
      return {
        isValid: false,
        message: `La quantité maximale de cartouches commandées ne doit pas excéder ${maxOrder}.\n
        Merci de vérifier les quantités de votre panier pour ne pas dépasser ce maximum.`
      }
    }
    return { isValid: true, message: '' }
  }

  // Case 3: Only maxOrder is not null
  if (maxOrder !== null) {
    if (quantity > maxOrder) {
      return {
        isValid: false,
        message: `La quantité maximale de cartouches commandées ne doit pas excéder ${maxOrder}.\n
          Merci de vérifier les quantités de votre panier pour ne pas dépasser ce maximum.`
      }
    }
    return { isValid: true, message: '' }
  }

  // Case 4: Only minOrder is not null
  if (minOrder !== null) {
    if (quantity < minOrder) {
      return {
        isValid: false,
        message: `La quantité minimale de cartouches commandées ne doit pas être inférieure à ${minOrder}.\n
          Merci de vérifier les quantités de votre panier.`
      }
    }
    return { isValid: true, message: '' }
  }

  // Default case
  return { isValid: false, message: '' }
}

const formatOrderData = (
  products: ProductType[],
  packages: PackageType[],
  accountNumber: string,
  audienceType: string,
  totalPoints: number | null,
  contactId: string,
  touchPoint: string,
  accoundEmail: string,
  accoundLastName: string,
  accoundFirstName: string,
  shopName: string,
  shopAddress: string,
  audience: AudienceType
): Partial<OrderType> => {
  return {
    accountNumber: accountNumber,
    audienceType: audienceType,
    products: mergeTwoProductArrays(products, formatProducts(packages)),
    totalPoints: totalPoints,
    contactId: contactId,
    touchPoint: touchPoint,
    accoundEmail: accoundEmail,
    accoundLastName: accoundLastName,
    accoundFirstName: accoundFirstName,
    shopName: shopName,
    shopAddress: shopAddress,
    audience: audience
  }
}

const formatProducts = (packages: PackageType[]): ProductType[] => {
  let formattedProducts: ProductType[] = []

  packages.forEach((pkg) => {
    const formattedPackageProducts = pkg.products.map((p: any) => ({
      ...p,
      quantity: pkg.quantity && (p.PackageProducts?.quantity * pkg.quantity || 0)
    }))
    formattedProducts = [...formattedProducts, ...formattedPackageProducts]
  })

  return formattedProducts
}

const mergeTwoProductArrays = (products: ProductType[], packageProducts: ProductType[]): ProductType[] => {
  const productMap = new Map<string, MergedProductType>() // Use product.code as the key

  // Merge product arrays with prioritization for 'products' over 'packageProducts'
  const mergeArray = (productArray: ProductType[], isFromProducts: boolean): void => {
    productArray.forEach((product) => {
      const existingProduct = productMap.get(product.code)

      if (existingProduct) {
        // If product already exists, add quantity
        productMap.set(product.code, {
          ...existingProduct,
          quantity: (existingProduct.quantity || 0) + (product.quantity || 0),
          fromProducts: existingProduct.fromProducts || isFromProducts // Keep the flag if it was already from products
        })
      } else {
        // If it does not exist, add it to the map
        // Prioritize products from "products" over "packages"
        productMap.set(product.code, { ...product, fromProducts: isFromProducts })
      }
    })
  }

  // Merge products array first (they take priority)
  mergeArray(products, true)

  // Merge package products array (they come after products, so no priority if products exist)
  mergeArray(packageProducts, false)

  // Return an array of products, prioritizing those from the 'products' list
  return Array.from(productMap.values()).map(({ fromProducts, ...rest }) => rest)
}

export default {
  validateOrderData,
  addOrder,
  formatOrderData,
  checkOrderFrequency,
  getOrderByAccountNumber
}
