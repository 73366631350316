import React from 'react'
import './admin-table.css'

interface Column {
  header: JSX.Element | string
  accessor: string
  render?: (row: any) => JSX.Element | string
}

interface AdminTableProps {
  columns: Column[]
  data: any[]
}

const AdminTable: React.FC<AdminTableProps> = ({ columns, data }) => {
  return (
    <table className='generic-table'>
      <thead>
        <tr>
          {columns.map((column, index) => (
            <th className='font__body--bold-small' key={index}>{column.header}</th>
          ))}
        </tr>
      </thead>
      <tbody className='package-table-admin'>
        {data.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {columns.map((column, columnIndex) => (
              <td key={columnIndex}>
                <div className='table-cell font__body--bold-small'>
                  {column.render
                    ? column.render(row)
                    : row[column.accessor] || row[column.accessor] === 0 ? row[column.accessor] : '--'}
                </div>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default AdminTable
