import React from 'react'
import './cart.css'
import { Link } from 'react-router-dom'
import { ReactComponent as CartIconBlack } from '../../assets/cart_black2.svg'
import { ReactComponent as CartIconWhite } from '../../assets/cart_white.svg'

interface CartProps {
  countCart?: number
}

const Cart: React.FC<CartProps> = ({ countCart }) => {
  const handleCartClick = (): void => {
    // Crée l'événement de message
    const event = new MessageEvent('message', {
      data: 'scroll-to-top',
      source: window // iframe
    })

    // Envoie l'événement à la fenêtre
    window.dispatchEvent(event)
  }
  return (
    <div className='cart-container' onClick={handleCartClick}>
      <Link to='/cart' className='cart-link'>
        <CartIconBlack className='cart-icon-black' />
        <CartIconWhite className='cart-icon-white' />
        <span className='cart-text font__body--bold-medium'>Mon panier</span>
        {countCart !== 0 && (
          <span className='cart-icon-counter total-count font__body--bold-medium'>
            {countCart}
          </span>
        )}
      </Link>
    </div>
  )
}

export default Cart
