import axios from 'axios'
import { type AudienceType } from '../utils/types'
import { getApiKey } from '../utils/apiKeyStorage'

const apiBaseUrl = process.env.REACT_APP_API_URL

const apiClient = axios.create({
  baseURL: apiBaseUrl
})

// Add a request interceptor to include the API key
apiClient.interceptors.request.use(
  async (config) => {
    const apiKey = getApiKey() // Synchronously fetch the API key
    console.log('apiKey ', apiKey)
    if (apiKey) {
      config.headers['x-api-key'] = apiKey // Add the API key to headers
    }
    return await Promise.resolve(config) // Explicitly return an awaited promise
  },
  async (error) => {
    return await Promise.reject(error) // Explicitly await the rejection
  }
)

const createAudience = async (
  newAudience: Partial<AudienceType>
): Promise<AudienceType> => {
  try {
    const response = await apiClient.post('/audiences', newAudience)
    return response.data
  } catch (error) {
    throw new Error('Network error')
  }
}

const getAllAudiences = async (): Promise<AudienceType[]> => {
  try {
    const response = await apiClient.get<AudienceType[]>('/audiences')
    return response.data
  } catch (error) {
    throw new Error('Network error')
  }
}

export const deleteAudience = async (id: number): Promise<void> => {
  try {
    const response = await apiClient.delete(`/audiences/${id}`)
    return response.data
  } catch (error) {
    throw new Error('Network error')
  }
}

const updateAudience = async (id: number, newObject: AudienceType): Promise<void> => {
  try {
    const response = await apiClient.put(`/audiences/${id}`, newObject)
    return response.data
  } catch (error) {
    throw new Error('Network error')
  }
}

const getAudienceByName = async (
  name: string
): Promise<AudienceType> => {
  try {
    const response = await apiClient.get(`/audiences/audience/${name}`)
    return response.data
  } catch (error) {
    throw new Error('Network error')
  }
}

export default {
  createAudience,
  getAllAudiences,
  deleteAudience,
  updateAudience,
  getAudienceByName
}
