import React, { type ReactNode, useEffect } from 'react'
import { useAppDispatch } from './hooks'
import { useSearchParams } from 'react-router-dom'
import { getInitAppParam } from '../services/appService'
import { fillUser } from '../slices/userSlice'

interface AppInitializerProps {
  children: ReactNode
}

const AppInitializer: React.FC<AppInitializerProps> = ({ children }) => {
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const initApp = async (): Promise<void> => {
      console.log('Initializing app')
      try {
        const appInitPayload = await getInitAppParam(searchParams.get('query'))
        dispatch(fillUser(appInitPayload))
      } catch (error) {
        console.error('Failed to initialize app:', error)
      }
    }
    void initApp()
  }, [])

  return <>{children}</>
}

export default AppInitializer
