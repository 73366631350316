import React from 'react'
import { ReactComponent as SpinnerIcon } from '../../../assets/spinner_icon.svg'
import './spinner.css'

interface SpinnerProps {
  className?: string
}

const Spinner: React.FC<SpinnerProps> = ({ className = '' }) => (
  <SpinnerIcon className={`${className} spinner`} />
)

export default Spinner
