import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type ProductType } from '../utils/types'

interface ProductListState {
  productList: ProductType[]
}

const initialState: ProductListState = {
  productList: []
}

const productListSlice = createSlice({
  name: 'productList',
  initialState,
  reducers: {
    updateProductList: (state, action: PayloadAction<ProductType[]>) => {
      state.productList = action.payload
    }
  }
})
export const { updateProductList } = productListSlice.actions
export default productListSlice.reducer
