import React, { type ReactNode } from 'react'
import './pagination.css'

interface PaginationProps {
  currentPage: number
  productList: any[]
  productPerPage: number
  isActivePagination?: boolean
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  children?: ReactNode | string
}

const Pagination: React.FC<PaginationProps> = (props) => {
  const {
    currentPage,
    productList,
    productPerPage,
    setCurrentPage
  } = props

  // Pagination function
  const totalFilteredProducts = productList.length
  const totalFilteredPages = Math.ceil(totalFilteredProducts / productPerPage)
  const pages = Array.from({ length: totalFilteredPages }, (_, i) => i + 1)

  return (
    <div className={`pagination font__body--large ${props.isActivePagination ? 'filter-active-pagination' : ''}`}>
      Page
      <div className='pagination-item-list'>
        {pages.length === 0
          ? (
            <div
              className={'pagination-item font__body--bold-large active-page'}
            >
              1
            </div>
            )
          : (
              pages.map((page, index) => (
              <div
                key={index}
                className={`pagination-item font__body--bold-large ${page === currentPage ? 'active-page' : ''}`}
                onClick={() => { setCurrentPage(page) }}
              >
                {page}
              </div>
              ))
            )
        }
        {props.children ? props.children : '' }
      </div>
    </div>
  )
}

export default Pagination
