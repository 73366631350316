import React, { type ReactNode, type ButtonHTMLAttributes } from 'react'
import './main-button.css'

interface MainButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode | string
  onClick?: () => void
  className?: string
  countCart?: number
}

const MainButton: React.FC<MainButtonProps> = ({ children, onClick, className, ...props }) => (
  <button
    className={`main-button font__body--bold-medium ${className || ''}`}
    onClick={onClick}
    {...props}
  >
    {children}
  </button>
)

export default MainButton
