import axios from 'axios'
import AWS from 'aws-sdk'

const apiBaseUrl = process.env.REACT_APP_API_URL

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION
})

const s3 = new AWS.S3()
const bucketName = process.env.REACT_APP_S3_BUCKET_NAME

if (!bucketName) {
  throw new Error(
    'Bucket name is not defined. Please set the REACT_APP_S3_BUCKET_NAME environment variable.'
  )
}

// Upload image and return the key
const uploadImage = async (file: File): Promise<string> => {
  const randomNumber = Math.floor(100000 + Math.random() * 900000)
  const fileExtension = file.name.split('.').pop()
  const newFileName = `image__${file.name.split('.')[0]}__${randomNumber}.${fileExtension}`

  const params = {
    Bucket: bucketName,
    Key: `images/${newFileName}`,
    Body: file,
    ContentType: file.type
  }

  try {
    await s3.upload(params).promise()
    console.log('File uploaded successfully')
    return `images/${newFileName}` // Return the key
  } catch (error) {
    console.error('Error uploading file:', error)
    throw error
  }
}

// List images and return pre-signed URLs
const listImages = async (): Promise<string[]> => {
  const params = {
    Bucket: bucketName,
    Prefix: 'images/'
  }

  try {
    const data = await s3.listObjectsV2(params).promise()
    const validKeys = data.Contents?.filter(item => item.Key && item.Key !== 'images/') || []
    const keys = validKeys.map((item) => item.Key as string)
    return keys
  } catch (error) {
    console.error('Error listing images:', error)
    return []
  }
}

// Delete an image by its key
const deleteImage = async (filename: string): Promise<void> => {
  const segments = filename.split('/')
  const params = {
    Bucket: bucketName,
    Key: `images/${segments[segments.length - 1].split('?')[0]}`
  }

  try {
    await s3.deleteObject(params).promise().then(async () => {
      await axios.delete(`${apiBaseUrl}/products/delete-image/${filename}`)
    })
    console.log('File deleted successfully')
  } catch (error) {
    console.error("Une erreur est survenue lors de l'importation des images.")
  }
}

// Update product image using the image key
const updateProductImage = async (
  productId: number,
  imageUrl: string
): Promise<void> => {
  console.log('url', imageUrl)
  try {
    await axios.patch(`${apiBaseUrl}/products/update-image/${productId}`, {
      imageUrl
    })
    console.log('API call successful')
  } catch (error) {
    console.error('API call failed:', error)
  }
}

const updatePackageImage = async (
  packageId: number,
  imageUrl: string
): Promise<void> => {
  try {
    await axios.patch(`${apiBaseUrl}/packages/update-image/${packageId}`, {
      imageUrl
    })
    console.log('API call successful')
  } catch (error) {
    console.error('API call failed:', error)
  }
}

const getImageUrl = (imageKey: string): string => {
  return s3.getSignedUrl('getObject', {
    Bucket: bucketName,
    Key: imageKey,
    Expires: 60 * 60 * 24 * 7 // 1 week expiration
  })
}

export default {
  uploadImage,
  listImages,
  deleteImage,
  updateProductImage,
  getImageUrl,
  updatePackageImage
}
