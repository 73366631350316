import React, { type ReactNode, type ButtonHTMLAttributes } from 'react'
import './admin-button.css'

interface AdminButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode | string
  onClick?: () => void
  className?: string
}

const AdminButton: React.FC<AdminButtonProps> = ({ children, onClick, className, ...props }) => (
  <button
    className={`admin-button font__body--bold-medium ${className}`}
    onClick={onClick}
    {...props}
  >
    {children}
  </button>
)

export default AdminButton
