import React, { type ReactElement } from 'react'
import './forbidden-order.css'
import { Link, useLocation } from 'react-router-dom'
import { ReactComponent as OrderHistoryIcon } from '../../assets/order-history.svg'
import forbiddenOrderImg from '../../assets/forbidden-order.png'

const ForbiddenOrder = (): ReactElement => {
  const { totalDaysLeftBeforeReOrdering } = useLocation().state

  return (
    <div className='forbidden-order-page'>
      <Link to='/order-list' className='order-history-link mobile'>
        <OrderHistoryIcon />
        <div className='font__body--bold-medium'>Historique de commande</div>
      </Link>
      <div className='forbidden-order-container'>
        <Link to='/order-list' className='order-history-link desktop'>
          <OrderHistoryIcon />
          <div className='order-history-link-label font__body--bold-medium'>
            Historique de commande
          </div>
        </Link>
        <div className='forbidden-order-img'>
          <img
            src={forbiddenOrderImg}
            alt='Image de confirmation de commande'
          />
        </div>
        <div className='forbidden-order-mention-container'>
          <div className='forbidden-order-title font__body--bold-large'>
            Vous avez passé commande de nos produits récemment.
          </div>
          <div className='forbidden-order-mention font__body--bold-large'>
            Soyez rassuré vous pourrez de nouveau passer commande sur OPEN PM{' '}
            {totalDaysLeftBeforeReOrdering === 1
              ? 'demain'
              : `dans ${totalDaysLeftBeforeReOrdering ?? 'quelques'} jours`}
            .
          </div>
          <div className='forbidden-order-mention font__body--bold-large'>
            Vous pouvez également retrouver nos produits sur le site Logista
            France.
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForbiddenOrder
