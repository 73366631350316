import React, { type ReactNode } from 'react'
import './main-link.css'
import { Link } from 'react-router-dom'

interface MainLinkProps {
  children: ReactNode | string
  className?: string
  onClick?: () => void
  url: string
}

const MainLink: React.FC<MainLinkProps> = ({ url, children, className, ...props }) => (
  <Link
    to={url}
    className={`main-link ${className || ''}`}
    {...props}
  >
    {children}
  </Link>
)

export default MainLink
