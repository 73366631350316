import React, { useState } from 'react'
import './product-filter.css'
import { ReactComponent as PlusIcon } from '../../../assets/plus_icon.svg'
import { ReactComponent as LessIcon } from '../../../assets/less_icon_black.svg'

interface ProductFilterProps {
  filteredBrand: string[]
  brandList: string[]
  handleFilter: (brand: string) => void
  handleResetFilter: () => void
}

const ProductFilter: React.FC<ProductFilterProps> = (props) => {
  const {
    filteredBrand,
    brandList,
    handleFilter,
    handleResetFilter
  } = props

  const [filterBrandMenu, setFilterBrandMenu] = useState(false)
  const [isActive, setIsActive] = useState(false)

  const brandFilterContainerClassName = `brand-filter-container${filterBrandMenu ? ' active' : ''}`
  const allReferenceIsActive = filteredBrand.length === 0 || (brandList.length === filteredBrand.length)

  const filterBrandButton = brandList.map(brand => {
    const brandFilterLinkClassName =
    `brand-filter font__body--bold-large filter-header ${filteredBrand.includes(brand) ? 'brand-filter-active' : ''}`
    return (
      <div
        key={brand}
        className={brandFilterLinkClassName}
        onClick={() => {
          handleFilter(brand)
          setIsActive(!isActive)
        }}
      >
        {brand.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase())}
      </div>
    )
  })

  return (
    <div className='filter-menu-container'>
      <div className='filter-header font__title--h3'>
        <span>FILTRER PAR</span>
      </div>
      <div
        className={`filter-reference font__body--bold-large ${allReferenceIsActive ? 'active-filter' : ''}`}
        onClick={() => { handleResetFilter() }}
        >
          Toutes nos références
      </div>
      <div className={brandFilterContainerClassName}>
        <div
        className='filter-brand-header font__body--bold-large'
        onClick={() => { setFilterBrandMenu(!filterBrandMenu) }}
        >
          Marques
          {filterBrandMenu
            ? <LessIcon className='burger-icon' />
            : <PlusIcon className='burger-icon' />
          }
        </div>
        {filterBrandMenu &&
          <div className='brand-filter-list'>
            {filterBrandButton}
          </div>
        }
      </div>
    </div>
  )
}

export default ProductFilter
