import React, { useEffect, useState } from 'react'
import './list-audience.css'
import { type AudienceType } from '../../../utils/types'
import audienceService, { deleteAudience } from '../../../services/audienceService'
import AdminTable from '../../utils/adminTable/AdminTable'
import InfoModal from '../../utils/infoModal/InfoModal'
import Modal from '../../utils/modal/Modal'
import EditAudience from '../EditAudience/EditAudience'
import { ReactComponent as EditIcon } from '../../../assets/edit_icon.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/delete_icon.svg'
import ConfirmationModal from '../../utils/confirmationModal/ConfirmationModal'

interface ListAudienceProps {
  audiencesList: AudienceType[]
}

const ListAudience: React.FC<ListAudienceProps> = ({ audiencesList }) => {
  const [audiences, setAudiences] = useState<AudienceType[]>(audiencesList)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [selectedAudience, setSelectedAudience] = useState<
  Partial<AudienceType>
  >({
    name: '',
    frequency: 0,
    maxOrder: 0,
    minOrder: 0
  })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [modalText, setmodalText] = useState('')

  const columns = [
    { header: 'NOM', accessor: 'name' },
    { header: 'FREQUENCE', accessor: 'frequency' },
    { header: 'MIN DE COMMANDE', accessor: 'minOrder' },
    { header: 'MAX DE COMMANDE', accessor: 'maxOrder' },
    {
      header: 'ACTIONS',
      accessor: 'actions',
      render: (row: AudienceType) => (
        <>
          <EditIcon
            className='admin-icons'
            onClick={() => {
              updateAudience(row)
            }}
            style={{ marginRight: '10px', color: 'green' }}
          >
            Update
          </EditIcon>
          <DeleteIcon
            className='admin-icons'
            onClick={() => {
              setSelectedAudience(row)
              setIsConfirmationModalOpen(true)
            }}
          >
            Delete
          </DeleteIcon>
        </>
      )
    }
  ]

  const deleteAudienceAction = async (id: number): Promise<void> => {
    try {
      await deleteAudience(id).then(() => {
        setmodalText("L'audience a été supprimée avec succès")
        setIsModalOpen(true)
        setAudiences(audiences.filter((a) => a.id !== id))
      })
    } catch (error) {
      console.log(error)
    }
  }

  const updateAudience = (audience: AudienceType): void => {
    setSelectedAudience(audience)
    setIsEditModalOpen(true)
  }

  const handleEditAudience = async (): Promise<void> => {
    try {
      const response = await audienceService.getAllAudiences()
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setAudiences(response)
    } catch (error) {
      console.error('Failed to fetch audiences:', error)
    }
  }

  useEffect(() => {
    setAudiences(audiencesList)
  }, [audiencesList])

  return (
    <div className='audiences-container-list'>
      <AdminTable columns={columns} data={audiences} />
      <InfoModal
        isOpen={isModalOpen}
        title="Suppression de l'audience"
        message={modalText}
        onClose={() => {
          setIsModalOpen(false)
        }}
      />
      <Modal
        isOpen={isEditModalOpen}
        title='Mettre à jour une audience'
        onClose={() => {
          setIsEditModalOpen(false)
        }}
      >
        <EditAudience
          audienceProp={selectedAudience}
          closeModal={() => {
            setIsEditModalOpen(false)
          } }
          onEditAudience={handleEditAudience} audiences={audiencesList} />
      </Modal>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        title='Supprimer une audience'
        message='Voulez-vous supprimer cette audience ?'
        onClose={() => {
          setIsConfirmationModalOpen(false)
        }}
        onConfirme={async () => {
          if (selectedAudience.id) {
            await deleteAudienceAction(selectedAudience.id)
            setIsConfirmationModalOpen(false)
          }
        }}
      ></ConfirmationModal>
    </div>
  )
}

export default ListAudience
