import axios from 'axios'
import { type ProductType } from '../utils/types'
import { getApiKey } from '../utils/apiKeyStorage'

const apiBaseUrl = process.env.REACT_APP_API_URL

const apiClient = axios.create({
  baseURL: apiBaseUrl
})

// Add a request interceptor to include the API key
apiClient.interceptors.request.use(
  async (config) => {
    const apiKey = getApiKey() // Synchronously fetch the API key
    console.log('apiKey ', apiKey)
    if (apiKey) {
      config.headers['x-api-key'] = apiKey // Add the API key to headers
    }
    return await Promise.resolve(config) // Explicitly return an awaited promise
  },
  async (error) => {
    return await Promise.reject(error) // Explicitly await the rejection
  }
)

const createProduct = async (newProduct: any): Promise<ProductType> => {
  try {
    const response = await apiClient.post('/products', newProduct)
    return response.data
  } catch (error) {
    throw new Error('Network error')
  }
}

const getAllProducts = async (): Promise<ProductType[]> => {
  try {
    console.log('REACT_APP_API_URL: ', process.env.REACT_APP_API_URL)
    console.log('REACT_APP_KEY_WA: ', process.env.REACT_APP_KEY_WA)
    console.log('REACT_APP_AWS_ACCESS_KEY_ID: ', process.env.REACT_APP_AWS_ACCESS_KEY_ID)
    const response = await apiClient.get<ProductType[]>('/products')
    return response.data
  } catch (error) {
    throw new Error('Network error')
  }
}

const deleteProduct = async (id: number): Promise<void> => {
  try {
    const response = await apiClient.delete(`/products/${id}`)
    return response.data
  } catch (error) {
    throw new Error('Network error')
  }
}

const getProductsByAudience = async (
  id: number,
  page: number,
  limit: number = 9999
): Promise<{
  totalItems: number
  products: ProductType[]
  totalPages: number
  currentPage: number
}> => {
  try {
    const response = await apiClient.get(
      `/products/product-by-audience/${id}`,
      {
        params: { page, limit }
      }
    )
    return response.data
  } catch (error) {
    throw new Error('Network error')
  }
}

const updateProduct = async (id: number, newObject: any): Promise<void> => {
  try {
    const response = await apiClient.put(`/products/${id}`, newObject)
    return response.data
  } catch (error) {
    throw new Error('Network error')
  }
}

const importProducts = async (file: FormData, imageUrls: any): Promise<void> => {
  try {
    file.append('imageUrls', JSON.stringify(imageUrls))
    const response = await apiClient.post('/products/import', file, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    console.log('File uploaded successfully', response.data)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.data) {
      const errorMessages = error.response.data.errors
      if (Array.isArray(errorMessages)) {
        const errorMessageString = errorMessages.join('\n')
        throw new Error(errorMessageString)
      } else {
        throw new Error(error.response.data.message as string)
      }
    } else {
      throw new Error(
        "Une erreur est survenue lors de l'importation des produits."
      )
    }
  }
}

const downloadExportedProducts = async (): Promise<void> => {
  try {
    const response = await apiClient.get('/products/export', {
      responseType: 'blob'
    })

    const file = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    })

    const fileURL = URL.createObjectURL(file)

    const link = document.createElement('a')
    link.href = fileURL
    link.setAttribute('download', 'fichier_produits.xlsx')
    document.body.appendChild(link)
    link.click()

    if (link.parentNode) {
      link.parentNode.removeChild(link)
    }
    URL.revokeObjectURL(fileURL)
  } catch (error) {
    console.error('Failed to download the file:', error)
  }
}

const disableProduct = async (
  id: number,
  enabled: boolean
): Promise<Partial<ProductType>> => {
  try {
    return await apiClient.put(`/products/disable/${id}`, { enabled })
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(
      `Une erreur est survenue lors de la désactivation du produit ${id}:`,
      error
    )
    return {}
  }
}

const updateProductRank = async (
  productId: number,
  updatedData: number,
  audienceId: number
): Promise<number> => {
  try {
    // Make a PATCH request to the API to update the product
    const response = await apiClient.patch(
      `/products/${productId}/rank`,
      { newRank: updatedData, audienceId: audienceId }
    )
    return response.data.newRank // Return the updated product data
  } catch (error) {
    console.error('Error updating product:', error)
    throw error // Propagate the error so it can be handled by the caller
  }
}

const bulkDeleteProducts = async (productIds: number[]): Promise<void> => {
  try {
    await apiClient.delete('/products/bulk-delete', {
      data: { productIds },
      headers: { 'Content-Type': 'application/json' }
    })
  } catch (error) {
    console.error('Error deleting products:', error)
    throw new Error('Error occurred while attempting bulk delete.')
  }
}

export default {
  createProduct,
  getAllProducts,
  deleteProduct,
  updateProduct,
  importProducts,
  getProductsByAudience,
  downloadExportedProducts,
  disableProduct,
  updateProductRank,
  bulkDeleteProducts
}
