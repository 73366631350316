import React, { type ButtonHTMLAttributes, useState, useEffect } from 'react'
import { type ProductType } from '../../../utils/types'
import { ReactComponent as BinIcon } from '../../../assets/bin_white.svg'
import { ReactComponent as PlusIcon } from '../../../assets/plus_white.svg'
import { ReactComponent as MinusIcon } from '../../../assets/less_white.svg'
import './handle-cart-button.css'
import { useAppDispatch } from '../../../utils/hooks'
import { clearProductsCart, setLessQuantity, setMoreQuantity, setUpdatedQuantity } from '../../../slices/cartSlice'

interface HandleCartButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  product: ProductType
  getProductQuantity: () => number
}

const HandleCartButton: React.FC<HandleCartButtonProps> = ({ product, getProductQuantity }) => {
  const dispatch = useAppDispatch()
  const quantityValue = getProductQuantity()
  const [isLessButtonDisabled, setIsLessButtonDisabled] = useState(true)
  const [isMoreButtonDisabled, setIsMoreButtonDisabled] = useState(!(product.minToOrder && product.maxToOrder && product.maxToOrder > product.minToOrder))

  useEffect(() => {
    checkDisabledButtons()
  }, [getProductQuantity])

  const checkDisabledButtons: () => void = () => {
    (!product.minToOrder && getProductQuantity() <= 1) || (product.minToOrder && getProductQuantity() <= product.minToOrder) ? setIsLessButtonDisabled(true) : setIsLessButtonDisabled(false)
    product.maxToOrder && getProductQuantity() >= product.maxToOrder ? setIsMoreButtonDisabled(true) : setIsMoreButtonDisabled(false)
  }

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newQuantityValue = parseInt(e.target.value, 10)
    dispatch(setUpdatedQuantity({ product, quantity: newQuantityValue }))
  }

  // Check if user input exceed min max quantity limit
  const handleBlur = (): void => {
    let newQuantity = quantityValue
    if (product.maxToOrder && quantityValue > product.maxToOrder) {
      newQuantity = product.maxToOrder
    }
    if (product.minToOrder && quantityValue < product.minToOrder) {
      newQuantity = product.minToOrder
    }
    dispatch(setUpdatedQuantity({ product, quantity: newQuantity }))
  }

  return (
    <div className='addToCart add-to-cart-package'>
      <button className={'addToCart__buttons addToCart__buttons-product addToCart__buttons--bin'}
        onClick={() => { dispatch(clearProductsCart(product)) }}>
        <BinIcon />
      </button>

      <button
        className={`addToCart__buttons addToCart__buttons-product addToCart__buttons--quantity ${isLessButtonDisabled ? 'addToCart__buttons--disabled' : ''}`}
        onClick={isLessButtonDisabled ? undefined : () => dispatch(setLessQuantity(product))}
      >
        <MinusIcon />
      </button>

      <input
        type="number"
        className='addToCart__input'
        value={quantityValue}
        onChange={handleQuantityChange}
        onBlur={handleBlur}
        min={product.minToOrder !== null ? product.minToOrder : undefined}
        max={product.maxToOrder !== null ? product.maxToOrder : undefined}
      />

      <button
        className={`addToCart__buttons addToCart__buttons-product addToCart__buttons--quantity ${isMoreButtonDisabled ? 'addToCart__buttons--disabled' : ''}`}
        onClick={isMoreButtonDisabled ? undefined : () => dispatch(setMoreQuantity(product))}
      >
        <PlusIcon />
      </button>
    </div>
  )
}

export default HandleCartButton
