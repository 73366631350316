import { type PackageType, type ProductType } from '../utils/types'

export const getUniqueBrandList = (catalogProducts: ProductType[], packageProducts: PackageType[]): string[] => {
  const allProductsBrands = catalogProducts.map(product => product.brand)

  const allPackageBrands = packageProducts.flatMap(pkg =>
    pkg.products.map(p => p.brand)
  )

  const allBrands = [...allProductsBrands, ...allPackageBrands]

  const uniqueBrands = allBrands.filter((brand, index) => allBrands.indexOf(brand) === index)

  return uniqueBrands
}

export const formatDate = (dateString: string): string => {
  const date = new Date(dateString)
  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear()
  return `${day}/${month}/${year}`
}
