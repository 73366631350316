import axios from 'axios'
import { type ProductType } from '../utils/types'
import { getApiKey } from '../utils/apiKeyStorage'

const apiBaseUrl = process.env.REACT_APP_API_URL

const apiClient = axios.create({
  baseURL: apiBaseUrl
})

// Add a request interceptor to include the API key
apiClient.interceptors.request.use(
  async (config) => {
    const apiKey = getApiKey() // Synchronously fetch the API key
    console.log('apiKey ', apiKey)
    if (apiKey) {
      config.headers['x-api-key'] = apiKey // Add the API key to headers
    }
    return await Promise.resolve(config) // Explicitly return an awaited promise
  },
  async (error) => {
    return await Promise.reject(error) // Explicitly await the rejection
  }
)

const createPreOrder = async (id: number, deliveryDate: Date): Promise<ProductType> => {
  try {
    const response = await apiClient.patch(`/preOrder/update-preOrder/${id}`, { deliveryDate })
    return response.data
  } catch (error) {
    throw new Error('Network error')
  }
}

const getAllPreOrders = async (): Promise<ProductType[]> => {
  try {
    const response = await apiClient.get<ProductType[]>('/preOrder')
    return response.data
  } catch (error) {
    throw new Error('Network error')
  }
}

const deletePreOrder = async (id: number): Promise<void> => {
  try {
    const response = await apiClient.delete(`/preOrders/${id}`)
    return response.data
  } catch (error) {
    throw new Error('Network error')
  }
}

const getPreOrdersById = async (
  id: number
): Promise<ProductType> => {
  try {
    const response = await axios.get(
      `${apiBaseUrl}/preOrder/${id}`
    )
    return response.data
  } catch (error) {
    throw new Error('Network error')
  }
}

const updatePreOrder = async (id: number, newObject: any): Promise<void> => {
  try {
    const response = await axios.put(`${apiBaseUrl}/preOrders/${id}`, newObject)
    return response.data
  } catch (error) {
    throw new Error('Network error')
  }
}

const importPreOrders = async (file: FormData, imageUrls: any): Promise<void> => {
  try {
    file.append('imageUrls', JSON.stringify(imageUrls))
    const response = await axios.post(`${apiBaseUrl}/preOrders/import`, file, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    console.log('File uploaded successfully', response.data)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.data) {
      const errorMessages = error.response.data.errors
      if (Array.isArray(errorMessages)) {
        const errorMessageString = errorMessages.join('\n')
        throw new Error(errorMessageString)
      } else {
        throw new Error(error.response.data.message as string)
      }
    } else {
      throw new Error(
        "Une erreur est survenue lors de l'importation des produits."
      )
    }
  }
}

const downloadExportedPreOrders = async (): Promise<void> => {
  try {
    const response = await axios.get(`${apiBaseUrl}/preOrders/export`, {
      responseType: 'blob'
    })

    const file = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    })

    const fileURL = URL.createObjectURL(file)

    const link = document.createElement('a')
    link.href = fileURL
    link.setAttribute('download', 'fichier_produits.xlsx')
    document.body.appendChild(link)
    link.click()

    if (link.parentNode) {
      link.parentNode.removeChild(link)
    }
    URL.revokeObjectURL(fileURL)
  } catch (error) {
    console.error('Failed to download the file:', error)
  }
}

const disablePreOrder = async (
  id: number,
  enabled: boolean
): Promise<Partial<ProductType>> => {
  try {
    return await axios.put(`${apiBaseUrl}/preOrders/disable/${id}`, { enabled })
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(
      `Une erreur est survenue lors de la désactivation du produit ${id}:`,
      error
    )
    return {}
  }
}

const updatePreOrderRank = async (
  preOrderId: number,
  updatedData: number,
  audienceId: number
): Promise<number> => {
  try {
    // Make a PATCH request to the API to update the preOrder
    const response = await axios.patch(
      `${apiBaseUrl}/preOrders/${preOrderId}/rank`,
      { newRank: updatedData, audienceId: audienceId }
    )
    return response.data.newRank // Return the updated preOrder data
  } catch (error) {
    console.error('Error updating preOrder:', error)
    throw error // Propagate the error so it can be handled by the caller
  }
}

const bulkDeletePreOrders = async (preOrderIds: number[]): Promise<void> => {
  try {
    await axios.delete(`${apiBaseUrl}/preOrders/bulk-delete`, {
      data: { preOrderIds },
      headers: { 'Content-Type': 'application/json' }
    })
  } catch (error) {
    console.error('Error deleting preOrders:', error)
    throw new Error('Error occurred while attempting bulk delete.')
  }
}

export default {
  createPreOrder,
  getAllPreOrders,
  deletePreOrder,
  updatePreOrder,
  importPreOrders,
  getPreOrdersById,
  downloadExportedPreOrders,
  disablePreOrder,
  updatePreOrderRank,
  bulkDeletePreOrders
}
