import React, { useEffect, useState, useMemo } from 'react'
import './cart-panel-card.css'
import { type ProductType } from '../../../utils/types'
import HandleCartButton from '../../utils/handleCartButton/HandleCartButton'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import { updateTotalPoints } from '../../../slices/cartSlice'
import { getProductTotalPoints } from '../../../services/pointsService'
import imageService from '../../../services/imageService'
import MacaronPoints from '../../utils/macaronPoints/macaronPoints'
import GoldenTickerImg from '../../../assets/golden-ticket.png'

interface CartPanelCardProps {
  product: ProductType
}

const CartPanelCard: React.FC<CartPanelCardProps> = ({ product }) => {
  const dispatch = useAppDispatch()
  const productsList = useAppSelector((state) => state.cart.products)
  const [prevPointsValue, setPrevPointsValue] = useState<number | null>(null)
  const pointsValue = getProductTotalPoints(product)

  const getProductQuantity: () => any = () => {
    const foundProduct = productsList.find((item) => item.id === product.id)
    return foundProduct ? foundProduct.quantity : 0
  }

  useEffect(() => {
    if (prevPointsValue !== pointsValue) {
      dispatch(updateTotalPoints({ product, pointsValue }))
      setPrevPointsValue(pointsValue)
    }
  }, [product])

  const imageUrl = useMemo(() => {
    return imageService.getImageUrl(product.imageUrl || '')
  }, [product.imageUrl])

  return (
    <>
      <td className='image cart-card__info'>
        <img className='product-cart-img' src={imageUrl} />
      </td>
      <td className='code cart-card__info'>{product.code}</td>
      <td className='name cart-card__info'>{product.label}</td>
      <td className='quantity cart-card__info cart-card__info-package'>
        <HandleCartButton
          product={product}
          getProductQuantity={getProductQuantity}
        ></HandleCartButton>
      </td>
      {/*       <td className='points cart-card__info'>
        {pointsValue && product.totalPoints && (
          <MacaronPoints points={pointsValue} />
        )}
      </td> */}
      <td className='points cart-card__info'>
        {pointsValue && product.pointValue !== 0 && product.totalPoints ? (
          <MacaronPoints points={pointsValue} />
        ) : (
          product.pointValue === 0 && (
            <div className='golden-ticket-cart'>
              <img src={GoldenTickerImg} width={100} height={40} />
            </div>
          )
        )}
      </td>
    </>
  )
}

export default CartPanelCard
