import React from 'react'
import './macaron-points.css' // Import the CSS for styles

interface MacaronPointsProps {
  points: number | null
  className?: string
}

const MacaronPoints: React.FC<MacaronPointsProps> = ({ points, className }) => {
  if (!points) {
    return null
  }

  return (
    <div className={`macaron-container ${className}`}>
      <div className='macaron'>
        <div className='macaron-text'>
          <span className='points-value'>+{points}</span>
          <span className='points-label'>points</span>
        </div>
      </div>
    </div>
  )
}

export default MacaronPoints
