import React from 'react'
import './confirmation-modal.css'
import AdminButton from '../adminButton/AdminButton'

interface ConfirmationModalProps {
  isOpen: boolean
  onClose: () => void
  onConfirme: () => void
  title?: string
  message: string
  subMessage?: string
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirme,
  title,
  message,
  subMessage
}) => {
  if (!isOpen) return null

  return (
    <div className='modal-overlay' onClick={onClose}>
      <div
        className='confirmation-modal-content'
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <button className='modal-close' onClick={onClose}>
          &times;
        </button>
        <div className='confirmation-modal-title'>
          <div>{title}</div>
        </div>
        <div className='confirmation-modal-body'>{message}</div>
        {subMessage && <div className='confirmation-modal-body'>{subMessage}</div>}
        <div className='modal-buttons-container'>
          <AdminButton
            style={{
              backgroundColor: 'var(--success-color)'
            }}
            onClick={onConfirme}
          >
            Confirmer
          </AdminButton>
          <AdminButton
            style={{
              backgroundColor: 'var(--error-color)'
            }}
            onClick={onClose}
          >
            Annuler
          </AdminButton>
        </div>
      </div>
    </div>
  )
}

export default ConfirmationModal
