import React, { useState } from 'react'
import './admin-dropdown.css'

interface Option {
  value: string | number
  label: string
}

interface AdminDropdownProps {
  options: Option[]
  placeholder: string
  value: string | number | undefined
  onChange: (value: string | number) => void
}

const AdminDropdown: React.FC<AdminDropdownProps> = ({
  options,
  placeholder,
  value,
  onChange
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const handleToggle = (): void => { setIsOpen(!isOpen) }
  const handleOptionClick = (optionValue: string | number): void => {
    onChange(optionValue)
    setIsOpen(false)
  }

  return (
    <div className='admin-dropdown'>
      <div className='admin-dropdown-header' onClick={handleToggle}>
        {value
          ? options.find((option) => option.value === value)?.label
          : placeholder}
      </div>
      {isOpen && (
        <div className='admin-dropdown-list'>
          {options.map((option) => (
            <div
              key={option.value}
              className='admin-dropdown-option'
              onClick={() => { handleOptionClick(option.value) }}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default AdminDropdown
